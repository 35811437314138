import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['number', 'priceLabel']

  connect () {
    this.submitBtn = document.getElementById('submitBtn')
  }

  numberIncrement (event) {
    const currentTarget = event.currentTarget.dataset.id;
    const targetNumber = document.getElementById(currentTarget);
    const isFloatOrBigDecimal = ['Float', 'BigDecimal'].includes(targetNumber.dataset.numbertype);

    if (isFloatOrBigDecimal) {
      const currentValue = parseFloat(targetNumber.value) || 0;
      targetNumber.value = (currentValue + 1).toFixed(2);
    } else {
      targetNumber.value = parseFloat(targetNumber.value)+1;
    }
    targetNumber.classList.remove('border-red-500')
    this.validateNumber();
    this.calculatePrice(event);
  }

  numberDecrement (event) {
    const currentTarget = event.currentTarget.dataset.id;
    const targetNumber = document.getElementById(currentTarget);

    if (targetNumber.value > (targetNumber.min || 1)) {
      const isFloatOrBigDecimal = ['Float', 'BigDecimal'].includes(targetNumber.dataset.numbertype);

      if (isFloatOrBigDecimal) {
        targetNumber.value = (parseFloat(targetNumber.value) - 1).toFixed(1);
      } else {
        targetNumber.value = parseFloat(targetNumber.value)-1;
      }
      this.validateNumber();
      this.calculatePrice(event);
    }
  }

  validateNumber (event) {
    let isValid = true
    this.numberTargets.forEach(el => {
      if (el.value < (el.min || 1)) {
        isValid = false
        el.classList.add('border-red-500')
        if(event){
          event.preventDefault()
        }
      } else {
        el.classList.remove('border-red-500')
      }
    })

    this.submitBtn.disabled = !isValid
  }

  validateAtLeastOneNonZero(event) {
  const atLeastOneNonZero = this.numberTargets.some(el => el.value > 0);

  this.numberTargets.forEach(el => {
    if (atLeastOneNonZero) {
      el.classList.remove('border-red-500');
    } else {
      el.classList.add('border-red-500');
    }
  });

  this.submitBtn.disabled = !atLeastOneNonZero;

  if (event && !atLeastOneNonZero) {
    event.preventDefault();
  }
}

  calculatePrice(event){
    if (this.hasPriceLabelTarget) {
      const productId = parseInt(event.target.dataset.productId);
      const priceElement = document.getElementById(`${productId}_price`);
      const qtyElement = document.getElementById(`${productId}_qunatity`);
      const price = parseFloat(priceElement.value || priceElement.getAttribute('value'));
      const qty = parseFloat(qtyElement.value || qtyElement.getAttribute('value'))
      const gstPercentage = parseFloat(event.target.dataset.gstPercentage);
      this.updatePriceTarget(productId, price, qty, gstPercentage);
    }
  }

  findTargetById(productId) {
    return this.numberTargets.find(target => target.id == productId);
  }

  updatePriceTarget(productId, price, qty, gstPercentage){
    const priceTarget = this.priceLabelTargets.find(target => target.id == productId)
    if( gstPercentage > 0){
      priceTarget.innerHTML = `Price To Customer (₹ ${(price * qty).toFixed(2)} + ₹ ${(price * qty * gstPercentage/100).toFixed(2)} GST) =  ₹ ${((price+(price*gstPercentage/100))*qty).toFixed(2)}`;
    } else {
      priceTarget.innerHTML = `Price To Customer (₹ ${price.toFixed(2)} * ${qty}) = ₹ ${(price * qty).toFixed(2)}`;
    }
  }
}