import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="profiles"
export default class extends Controller {
  static targets = ["accountNo", "confirmAccountNo", "submitButton", "errorMessage", "confirmAccountNoContainer"];
  connect() {
  }

  validateAccountNo(event){
    const accountNo = this.accountNoTarget.value;
    const initialAccountNo = this.accountNoTarget.dataset.initialValue;
    this.toggleConfirmAccountContainer(accountNo, initialAccountNo,event);
  }

  toggleConfirmAccountContainer(accountNo, initialAccountNo, event){
    const confirmContainerTarget = this.confirmAccountNoContainerTarget;
    if ((accountNo === initialAccountNo )) {
      confirmContainerTarget.classList.add("hidden");
      this.showSubmitButton();
      this.alertMsg("");
    } else if (accountNo === this.confirmAccountNoTarget.value ) {
      this.showSubmitButton();
      this.alertMsg("");
    } else {
      confirmContainerTarget.classList.remove("hidden");
      this.alertMsg("Passwords do not match");
      event.preventDefault();
    }
  }


  hideSubmitButton(){
    this.submitButtonTarget.disabled = true;
  }

  showSubmitButton(){
    this.submitButtonTarget.disabled = false;
  }

  alertMsg(msg){
    this.errorMessageTarget.textContent = msg;
  }
}
