import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets =['selectAll',"checkbox"]
  connect() {
  }

  toggleChildren() {
    if (this.selectAllTarget.checked) {
      this.checkboxTargets.map(x => x.checked = true)
    } else {
      this.checkboxTargets.map(x => x.checked = false)
    }
  }

  toggleParent() {
    if (this.checkboxTargets.map(x => x.checked).includes(false)) {
      this.selectAllTarget.checked = false
    } else {
      this.selectAllTarget.checked = true
    }
  }
}
