import { Controller } from "@hotwired/stimulus"
// Connects to data-controller="monthly-card-filter"
export default class extends Controller {
  static targets = ["customerId", "order", "month"]

  validateValues(event) {
    const targets = [
      { target: this.orderTarget, message: 'Please select order.' },
      { target: this.monthTarget, message: 'Please select month.' },
      { target: this.customerIdTarget, message: 'Please select customer name.' }
    ];

    targets.forEach(({ target, message }) => {
      if (target.value === '') {
        alert(message);
        event.preventDefault();
      }
    });
  }

}
