import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["mobile"]

  connect(){
    this.blurMobileNo();
  }

  toggleMobileBlur(event) {
    this.blurMobileNo();
    const encodedMobileNo = event.currentTarget.dataset.mobileNo;

    if (encodedMobileNo) {
      const decodedMobileNo = atob(encodedMobileNo);
      event.currentTarget.innerHTML = decodedMobileNo;
      event.currentTarget.classList.remove('blur-sm', 'cursor-pointer');
    }
  }

  blurMobileNo(){
    this.mobileTargets.forEach(element => {
      element.innerHTML = "0000000000";
      element.classList.add('blur-sm', 'cursor-pointer');
    });
  }
}