import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delete-user"
export default class extends Controller {
  static targets = ["mobileNumber","errorAlert"]
  connect() {
  }
  submitForm(event)
  {
    event.preventDefault();
    this.errorAlertTarget.classList.remove("alert", "alert-error")
    this.errorAlertTarget.innerHTML= ""
    const form = event.currentTarget.form;
    const mobile_no = this.mobileNumberTarget.value;
    const url =`/sales/get_user?mobile_no=${mobile_no}`;
    fetch(url,{
      method: "get",
      headers: {
        Accept: "text/html",
      }
    }).then(response=>response.json())
    .then(response => {
      if (response.success) {
      const agency_name = response.agency_name ? response.agency_name: "NA";
      let  confirmation =  confirm(`Name: ${response.user.name} \n Mobile_Number : ${response.mobile_no} \n Agency_Name : ${agency_name} \n Role : ${response.role}`)
        if (!confirmation) {
          return;
        }
      form.action = `/sales/delete_user/${response.user.id}`;
      Turbo.navigator.submitForm(form)
      }else{
        this.errorAlertTarget.classList.add("alert","alert-error")
        this.errorAlertTarget.innerHTML= response.error
      }
     })
  }
}
