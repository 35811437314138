import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-frame-loader"
export default class extends Controller {
  static targets = ["loader"];

  connect() {
    this.showLoader();
    document.addEventListener("turbo:before-fetch-request", this.showLoader.bind(this))
    document.addEventListener("turbo:before-cache", this.hideLoader.bind(this))
    document.addEventListener("turbo:load", this.hideLoader.bind(this))
  }

  disconnect() {
    document.removeEventListener("turbo:before-fetch-request", this.showLoader.bind(this))
    document.removeEventListener("turbo:before-cache", this.hideLoader.bind(this))
    document.removeEventListener("turbo:load", this.hideLoader.bind(this))
  }


  showLoader() {
    this.loaderTarget.classList.remove("hidden")
  }

  hideLoader() {
    this.loaderTarget.classList.add("hidden")
  }
}