import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="warn-and-block"
export default class extends Controller {
  static targets = [
    'isWarn',
    'isBlock',
    'isDueAmountType',
    'isAdvanceAmountType',
    'blockAmount',
    'warnAmount',
    'submit',
    'warnContainer',
    'blockContainer',
    'errorsContainer'
  ]
  connect() {
    this.warnHideable();
    this.blockHideable();
  }

  warnHideable(){
    const action = this.isWarnTarget.dataset.actionType;
    let targetContainer = this.warnContainerTarget;
    const isWarn = this.isWarnTarget.checked;
    const warnAmountTarget = this.warnAmountTarget;

    this.toggle(action, targetContainer, isWarn, warnAmountTarget);
  }

  blockHideable(){
      const action = this.isWarnTarget.dataset.actionType;
      let targetContainer = this.blockContainerTarget;
      const isBlock = this.isBlockTarget.checked;
      const blockAmountTarget = this.blockAmountTarget;

    this.toggle(action, targetContainer, isBlock, blockAmountTarget);
  }

  validate(event){
    const isDueAmountType = this.isDueAmountTypeTarget.checked;
    const isAdvanceAmountType = this.isAdvanceAmountTypeTarget.checked;
    const isBlock = this.isBlockTarget.checked;
    const isWarn = this.isWarnTarget.checked;
    const blockAmount = parseInt(this.blockAmountTarget.value);
    const warnAmount = parseInt(this.warnAmountTarget.value);
    if(isDueAmountType && (isBlock && isWarn) && (warnAmount > blockAmount))
    {
      this.errorsContainerTarget.innerHTML = " ERROR:<br><br> Warn amount can not be greater than due amount.";
      this.errorsContainerTarget.classList.add("p-2");
      event.preventDefault();
    }

    if(isAdvanceAmountType && (isBlock && isWarn) && (warnAmount < blockAmount))
    {
      this.errorsContainerTarget.innerHTML = " ERROR:<br><br> Warn amount can not be less than due amount.";
      this.errorsContainerTarget.classList.add("p-2");
      event.preventDefault();
    }
  }

  toggle(action, targetContainer, isWarnOrBlock, amountTarget){
    if(action === "new"){
      this.newActionToggle(isWarnOrBlock, targetContainer, amountTarget)
    }else if(action === "edit"){
      this.editActionToggle(isWarnOrBlock, targetContainer, amountTarget)
    }
  }

  newActionToggle(isWarnOrBlock, targetContainer, amountTarget){
    if(isWarnOrBlock){
      this.show(targetContainer);
    }else{
      amountTarget.value = 0.0;
      this.hide(targetContainer);
    }
  }

  editActionToggle(isWarnOrBlock, targetContainer, amountTarget){
    const amount = parseFloat(amountTarget.value);
    if(amount > 0.0 || (amount === 0.0 && isWarnOrBlock)){
      this.show(targetContainer);
    }else{
      this.hide(targetContainer);
    }
  }

  hide(targetContainer){
    if(!targetContainer.classList.contains('hidden')){
      targetContainer.classList.add('hidden');
    }
  }

  show(targetContainer){
    if(targetContainer.classList.contains('hidden')){
      targetContainer.classList.remove('hidden');
    }
  }
}
