import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fancy-select"
export default class extends Controller {
  static targets = ["list", "item", "selected", "input", "searchField"];

  connect() {
    this.selectedOption();
    document.addEventListener('click', this.handleOutsideClick.bind(this));
    this.activeIndex = -1;
    this.isNavigatingWithKeyboard = false;
  }

  toggle() {
    this.listTarget.classList.toggle("hidden");
    this.selectedTarget.classList.toggle("border-2", !this.listTarget.classList.contains("hidden"));
    this.selectedTarget.classList.toggle("border-gray-500", !this.listTarget.classList.contains("hidden"));
  }

  choose(event) {
    const selectedItem = event.currentTarget;
    const id = selectedItem.dataset.id;

    this.updateSelectedOption(selectedItem);
    this.inputTarget.value = id;
    this.close();
  }

  search(event) {
    const searchInput = event.target.value.toLowerCase();
    this.activeIndex = -1;

    this.itemTargets.forEach((item) => {
      const itemName = item.querySelector(".item-name").textContent.toLowerCase();
      item.classList.toggle("hidden", !itemName.includes(searchInput));
    });
  }

  clearText() {
    this.searchFieldTarget.value = '';
    this.itemTargets.forEach(item => item.classList.remove("hidden"));
  }

  clearSelectBox() {
    this.inputTarget.value = '';
    this.selectedTarget.innerHTML = this.selectedTarget.dataset.label;
    this.resetItems();
    this.close();
  }

  selectedOption() {
    const selectedValue = this.inputTarget.value.trim();
    if (selectedValue) {
      const selectedItem = this.itemTargets.find(item => item.dataset.id === selectedValue);
      if (selectedItem) {
        this.updateSelectedOption(selectedItem);
      }
    }
  }

  handleOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      this.close();
    }
  }

  navigate(event) {
    const key = event.key;
    this.isNavigatingWithKeyboard = true;

    if (key === "ArrowDown") {
      event.preventDefault();
      this.updateActiveIndex(1);
    } else if (key === "ArrowUp") {
      event.preventDefault();
      this.updateActiveIndex(-1);
    } else if (key === "Enter" && this.activeIndex >= 0) {
      event.preventDefault();
      this.selectItem(this.activeIndex);
    } else if (key === "Escape") {
      this.close();
    }

    // Reset hover behavior after keyboard navigation ends
    setTimeout(() => this.isNavigatingWithKeyboard = false, 0);
  }

  updateActiveIndex(index) {
    const visibleItems = this.visibleItems;
    this.activeIndex = Math.min(Math.max(this.activeIndex + index, 0), visibleItems.length - 1);
    this.updateActiveItem(visibleItems);
  }

  updateActiveItem(visibleItems) {
    this.resetItems();
    const activeItem = this.itemTargets[visibleItems[this.activeIndex]];
    if (activeItem) {
      activeItem.classList.add("bg-indigo-600", "text-white");
      activeItem.scrollIntoView({ block: "nearest" });
    }
  }

  selectItem(index) {
    const selectedItem = this.itemTargets[this.visibleItems[index]];
    selectedItem.click();  
  }

  updateSelectedOption(item) {
    this.selectedTarget.innerHTML = item.querySelector(`[data-js="label"]`).outerHTML;
    this.resetItems();
    item.classList.add("bg-indigo-600", "text-white");
    item.querySelector(`[data-js="title"]`).classList.add("font-semibold");
    item.querySelector(`[data-js="check"]`).classList.remove("hidden");
  }

  resetItems() {
    this.itemTargets.forEach(item => {
      item.classList.remove("bg-indigo-600", "text-white");
      item.querySelector(`[data-js="title"]`).classList.remove("font-semibold");
      item.querySelector(`[data-js="check"]`).classList.add("hidden");
    });
  }

  get visibleItems() {
    return this.itemTargets.reduce((indexes, item, index) => {
      if (!item.classList.contains("hidden")) indexes.push(index);
      return indexes;
    }, []);
  }

  close() {
    this.listTarget.classList.add("hidden");
    this.selectedTarget.classList.remove("border-2", "border-gray-500");
  }

  hover(event) {
    if (!this.isNavigatingWithKeyboard) { 
      const hoveredItem = event.currentTarget;
      const index = this.itemTargets.indexOf(hoveredItem);
      if (index !== -1) {
        this.activeIndex = this.visibleItems.indexOf(index);
        this.updateActiveItem(this.visibleItems);
      }
    }
  }
}
