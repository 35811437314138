import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-inventory"
export default class extends Controller {
  static targets = ['filledMissingJar',"emptyMissingJar","emptyUnloadQty","filledUnloadQty","deliveredQty","receivedQty","loadQty"]
  connect() {
  }

  checkMissingJar(loadQuantity, unloadQuantity, deliveredOrReceivedQty, missingJarTarget) {
    const missingJar = loadQuantity - deliveredOrReceivedQty - unloadQuantity;
  
    if (missingJar === 0 || isNaN(missingJar)) {
      missingJarTarget.classList.add("hidden");
    } else {
      missingJarTarget.querySelector("#jarCount").innerHTML = missingJar;
      missingJarTarget.classList.remove("hidden");

    }
  }
  
  checkFilledMissingJar() {
    const loadQuantity = parseInt(this.loadQtyTarget.value, 10);
    const filledUnloadQty = parseInt(this.filledUnloadQtyTarget.value, 10);
    const deliveredQty = parseInt(this.deliveredQtyTarget.innerHTML, 10);
  
    this.checkMissingJar(loadQuantity, filledUnloadQty, deliveredQty, this.filledMissingJarTarget);
  }
  
  checkEmptyMissingJar() {
    const receivedQty = parseInt(this.receivedQtyTarget.textContent, 10);
    const emptyUnloadQty = parseInt(this.emptyUnloadQtyTarget.value, 10);
    this.checkMissingJar(receivedQty, emptyUnloadQty, 0, this.emptyMissingJarTarget);
  }
  
}
