import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="loader"
export default class extends Controller {
  static targets = ['loader']
  connect() {
  }

  show() {
    this.loaderTarget.classList.remove("hidden");
  }

  hide() {
    this.loaderTarget.classList.add("hidden");
  }

}