import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll"
export default class extends Controller {
  connect() {
    if (this.element.dataset.scrollOnConnect == 'true') {
      this.triggerScroll();
    }
  }
  triggerScroll() {
    const targetId = this.element.getAttribute("data-target-id");
    if (targetId) {
      const targetSection = document.getElementById(targetId);
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
