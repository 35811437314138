import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="apply-gst"
export default class extends Controller {
  static targets = [
    'gstCalculatorModal',
    'amountField',
    'gstPercent',
    'finalProductCost',
    'finalGstCost',
    'finalTotalCost',
    'productCost',
    'gstCost',
    'totalCost',
    'appliedGstPercent',
    'appliedProductRate',
    'gstIncludeCheckbox',
  ];
  connect() {
    this.calculatePrice()
  }

  gstCalculator() {
    this.showGstCalculatorModal();
    this.updateFields();
    this.calculatePriceBreakout();
  }
  
  showGstCalculatorModal() {
    this.gstCalculatorModalTarget.classList.remove('hidden');
    document.getElementById('gst_popup').setAttribute('open', true);
  }
  
  updateFields() {
    const productRate = this.parseInput(this.amountFieldTarget.value);
    const gstPercent = this.parseInput(this.gstPercentTarget.value);
    this.appliedProductRateTarget.value = this.formatNumber(productRate);
    this.appliedGstPercentTarget.value = this.formatNumber(gstPercent);
    this.gstIncludeCheckboxTarget.checked = false;
  }
  
  parseInput(value) {
    let parsedValue = parseFloat(value);
    return isNaN(parsedValue) || parsedValue < 0 ? 0.0 : parsedValue;
  }
  
  formatNumber(value) {
    return Number.isInteger(value) ? value.toFixed(1) : value.toFixed(2);
  }
  
  calculatePriceBreakout() {
    const productRate = this.parseInput(this.appliedProductRateTarget.value);
    const gstPercent = this.parseInput(this.appliedGstPercentTarget.value);
    const isGstInclude = this.gstIncludeCheckboxTarget.checked;
  
    this.calculateCosts(productRate, gstPercent, isGstInclude);
  }
  
  calculateCosts(productRate, gstPercent, isGstInclude) {
    let productCost, gstCost, totalCost;
    if (isGstInclude) {
      gstCost = productRate - (productRate * (100 / (100 + gstPercent)));
      productCost = productRate - gstCost;
      totalCost = productRate;
    } else {
      gstCost = (productRate * gstPercent) / 100;
      productCost = productRate;
      totalCost = productRate + gstCost;
    }
    this.updateCostTargets(productCost, gstCost, totalCost);
  }
  
  updateCostTargets(productCost, gstCost, totalCost) {
    this.productCostTarget.innerHTML = this.formatNumber(productCost);
    this.gstCostTarget.innerHTML = this.formatNumber(gstCost);
    this.totalCostTarget.innerHTML = this.formatNumber(totalCost);
  }
  
  applyGst() {
    const productFinalAmount = this.parseInput(this.productCostTarget.innerHTML);
    const finalGstPercent = this.parseInput(this.appliedGstPercentTarget.value);
    const finalGstCost = this.parseInput(this.gstCostTarget.innerHTML);
    const totalCost = this.parseInput(this.totalCostTarget.innerHTML);
    
    this.amountFieldTarget.value = this.formatNumber(productFinalAmount);
    this.gstPercentTarget.value = this.formatNumber(finalGstPercent);
    this.updateFinalFields(productFinalAmount, finalGstCost, totalCost);

    const alert  = this.element.querySelector('#price-alert')
    if(alert && alert.innerHTML.trim() !== ''){
      alert.innerHTML = ""
      this.amountFieldTarget.classList.remove("border-red-600");
    }
    
    document.getElementById('gst_popup').removeAttribute('open');
  }
  
  updateFinalFields(productFinalAmount, finalGstCost, totalCost) {
    this.finalProductCostTarget.innerHTML = this.formatNumber(productFinalAmount);
    this.finalGstCostTarget.innerHTML = this.formatNumber(finalGstCost);
    this.finalTotalCostTarget.innerHTML = this.formatNumber(totalCost);
  }
  
  calculatePrice() {
    const productRate = this.parseInput(this.amountFieldTarget.value);
    const gstPercent = this.parseInput(this.gstPercentTarget.value);
    const gstCost = (productRate * gstPercent) / 100;
    const totalCost = productRate + gstCost;
  
    this.updateFinalFields(productRate, gstCost, totalCost);
  }
  
}

