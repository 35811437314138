import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-images-preview"
export default class extends Controller {
  static targets = ["previewContainer", "input"]

  preview(event) {
    const input = event.target
    const files = input.files

    this.previewContainerTarget.innerHTML = '' // Clear previous previews

    Array.from(files).forEach((file, index) => {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const imgContainer = document.createElement('div')
          imgContainer.className = 'relative inline-block m-2'

          const img = document.createElement('img')
          img.src = e.target.result
          img.className = 'w-20 h-20 rounded-md border border-gray-500' // Tailwind CSS classes
          imgContainer.appendChild(img)

          const deleteBtn = document.createElement('button')
          deleteBtn.className = 'absolute top-0 right-0 text-white rounded-full p-1 text-xs' // Tailwind CSS classes

          const deleteIcon = document.createElement('img')
          deleteIcon.src = '/assets/trash-can.png' // Update this to your actual path
          deleteIcon.className = 'w-4 h-4' // Tailwind CSS classes for the icon
          deleteBtn.appendChild(deleteIcon)

          deleteBtn.dataset.index = index
          deleteBtn.addEventListener('click', () => this.removeImage(index))
          imgContainer.appendChild(deleteBtn)

          this.previewContainerTarget.appendChild(imgContainer)
        }
        reader.readAsDataURL(file)
      }
    })
  }

  removeImage(index) {
    // Remove the image preview
    const filesArray = Array.from(this.previewContainerTarget.children)
    const targetFile = filesArray[index]

    if (targetFile) {
      this.previewContainerTarget.removeChild(targetFile)
    }

    // Remove the image from the input field
    const dataTransfer = new DataTransfer()
    const inputFiles = Array.from(this.inputTarget.files)

    inputFiles.forEach((file, i) => {
      if (i !== index) {
        dataTransfer.items.add(file)
      }
    })

    this.inputTarget.files = dataTransfer.files

    // Re-render the previews to update indices
    this.preview({ target: this.inputTarget })
  }
}
